import { FC } from "react"
import {
    BusinessInfo,
    Cta,
    Footer,
    Header,
    HighlightedFeatures,
    Qualifications,
    TeamMembers,
    TradeCategories,
} from "../../../components/crystal-peak/sections"
import { CrystalPeakAboutPageType } from "./about-helper.types"

export const AboutHelper: FC<CrystalPeakAboutPageType> = ({
    headerProps,
    footerProps,
    businessInfoProps,
    qualificationsProps,
    ctaAboutProps,
    teamMembersProps,
    highlightedFeaturesProps,
    tradeCategoriesProps,
    cNavigate,
}) => {
    return (
        <>
            <header>
                <Header {...headerProps} navigate={cNavigate} />
            </header>
            <main>
                <BusinessInfo {...businessInfoProps} />
                <Qualifications {...qualificationsProps} />
                <TeamMembers {...teamMembersProps} />
                <HighlightedFeatures {...highlightedFeaturesProps} />
                <TradeCategories {...tradeCategoriesProps} />
                <Cta {...ctaAboutProps} />
            </main>
            <footer>
                <Footer {...footerProps} />
            </footer>
        </>
    )
}

export default AboutHelper
