import { graphql, HeadFC, PageProps } from "gatsby"
import { websiteDto } from "../../../utils/website-dto/crystal-peak"
import { WebsitePayload } from "../../../utils/website-dto/website-payload"
import AboutHelper from "../helpers/about-helper"
import { CrystalPeakAboutPageType } from "../helpers/about-helper.types"

const AboutPage = ({
    data: {
        api: { website, business },
    },
    ...rest
}: PageProps<{ api: WebsitePayload }>) => {
    const requiredData = websiteDto({
        ...website,
        ...business,
        email: business?.emails?.[0]?.email ?? "",
        phoneNumber: business?.phones?.[0]?.phoneNumber ?? "",
    }) as unknown as CrystalPeakAboutPageType

    return <AboutHelper {...requiredData} cNavigate={rest.navigate} cLocation={rest.location} />
}
export const Head: HeadFC = () => <title>Home Page</title>

export default AboutPage

export const query = graphql`
    query ($businessId: String!) {
        api {
            website(where: { businessId: $businessId }) {
                ...WebsiteInfo
            }
            business(where: { id: $businessId }) {
                ...BusinessInfo
            }
        }
    }
`
